@import '../../../UI/global.module.scss';

$margin-bottom: 17px;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 71px;
  background-color: #fcfffe;
  padding: 80px 0 82px;

  .title {
    font-size: 48px;
		font-family: Roca One;
		font-weight: 400;
		line-height: 55px;
		letter-spacing: 1.2px;
		text-align: center;
		max-width: 1040px;
		margin-bottom: $margin-bottom;
  }

  .containerImages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 17px;
    background-color: #ffffff;
    max-width: 1444px;
		padding-bottom: 17px;

    .image {
      width: auto;
      height: auto;
      border-radius: 12px;
    }

		.mTop {
			margin-top: 93px;
		}

		.mBottom {
			margin-bottom: 93px;
		}
  }

  .containerCards {
    display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas: 
		'first first first'
		'default1 default2 default3';
    gap: 26px;
    background-color: #ffffff;
    max-width: 1333px;
		margin: 0 56px;

		.principalCard {
			grid-area: first;
			display: flex;
			border-radius: 32px;
			overflow: hidden;
			background-color: #dddaff;
			min-height: 330px;

			.text {
				width: calc(100% - 545px);
				height: 100%;
				padding: 48px;
				display: flex;
				flex-direction: column;
				gap: 32px;

				& ul {
					list-style: disc;
					display: flex;
					flex-direction: column;
					gap: 16px;
					padding-left: 36px;
				}
			}
			
			.imageCard {
				width: 545px;
				height: 100%;
				overflow: hidden;
				display: flex;
				align-items: center;
				
				& img {
					width: 100%;
				}
			}
		}

		.defaultCard {
			grid-area: default1 default2 default3;
			border-radius: 32px;
			padding: 48px;
			display: flex;
			flex-direction: column;
			gap: 32px;
		}

		.principalCard, .defaultCard {

			.titleCard {
				font-size: 32px;
				font-family: Roca One;
				font-weight: 400;
				line-height: 34px;
				letter-spacing: 1px;
			}

			.descriptionCard {
				font-size: 24px;
				font-family: NT Bau;
				font-weight: 400;
				line-height: 30px;
				letter-spacing: -0.5px;
			}
		}
  }

  .button {
		width: 490px;
    height: 54px;
		
		p {
			display: flex;
		}

		& > div {
			min-width: 16px;

			& svg {
				transform: scale(110%);
			}
		}
  }
}

.mainColor {
  color: $main-color;
}

.mobile {
  display: none;
}

.desktop {
	display: block;
}

.deskMob {
	display: block;
}

@media only screen and (max-width: 768px) {
  .container {
    gap: 32px;
    padding: 32px 0 40px;

		.title {
			font-size: 30px;
			line-height: 110%;
			letter-spacing: 0.6px;
			text-align: center;
			padding: 0 20px;
			margin-bottom: 8px;
		}

    .containerImages {
      grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 1fr;
			column-gap: 8px;
			padding-bottom: 0;

      .image {
        width: auto;
        height: auto;
      }

			.mTop {
				margin-top: 40px;
			}
	
			.mBottom {
				margin-bottom: 40px;
			}
    }

		.containerCards {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-areas: 
			'first'
			'default1'
			'default2'
			'default3';
			gap: 24px;
			background-color: #ffffff;
			max-width: 1333px;
			margin: 0 20px;
	
			.principalCard {
				grid-area: first;
				display: flex;
				flex-direction: column-reverse;
				border-radius: 32px;
				overflow: hidden;
				background-color: #dddaff;
				max-height: none;
	
				.text {
					width: 100%;
					padding: 24px;
					display: flex;
					flex-direction: column;
					gap: 20px;
					max-height: 360px;
	
					& ul {
						list-style: disc;
						display: flex;
						flex-direction: column;
						gap: 12px;
						padding-left: 24px;
					}
				}
				
				.imageCard {
					width: 100%;
					min-height: 180px;
					height: 180px;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					overflow: hidden;
					
					& img {
						width: 100%;
					}
				}
			}
	
			.defaultCard {
				grid-area: default1 default2 default3;
				border-radius: 32px;
				padding: 24px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				min-height: 224px;
			}
	
			.principalCard, .defaultCard {
	
				.titleCard {
					font-size: 24px;
					line-height: 30px;
					letter-spacing: 0.8px;
				}
	
				.descriptionCard {
					font-size: 18px;
					line-height: 24px;
					letter-spacing: -0.5px;
				}
			}
		}

    .button {
      width: calc(100% - 40px);
      height: 48px;
			margin: 8px 17px 0;
    }
  }

  .mobile {
    display: block;
  }

	.desktop {
		display: none;
	}
}
